/******************************************************************
Site Name:
Author:

Stylesheet: Base Mobile Stylesheet

Be light and don't over style since everything here will be
loaded by mobile devices. You want to keep it as minimal as
possible. This is called at the top of the main stylsheet
and will be used across all viewports.

******************************************************************/

/*********************
GENERAL STYLES
*********************/
body {
	font-family: $sans-serif;
	font-size: 97.5%;
	line-height: 1.6;
	color: $black;
    font-weight:400;
} 

.mobileOnly {display:inline-block;}
.desktopOnly {display:none;}

/********************
BODY CLASSES
style a page via class
********************/

.home {}    /* home page */


/*********************
Z_INDEX
*********************/
.cd-top { z-index: 9999;}
.header { z-index: 9999;}
#bookForm { z-index: 900;}

ul.children {z-index: 800;}


/*********************
LAYOUT & GRID STYLES
*********************/

.row, body.Home #content #leftcol > div > div, .row-small, body.fullWidth #leftcol > div > div {
	margin: 0 auto;
	width: 96%;
	padding: 1em 0;
}

/* Turn off padding on some rows*/
.header .row { padding: 0 0;}



/*********************
LINK STYLES
*********************/

a, a:visited {
	color: $link-color;

	/* removing text decoration from all headline links */
		text-decoration: none;

	/* on hover */
	&:hover, &:focus {
		color: $link-hover;
	}

	/* on click */
	&:active {

	}

	/* mobile tap color */
	&:link {
		/*
		this highlights links on iPhones/iPads.
		so it basically works like the :hover selector
		for mobile devices.
		*/
		-webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
	}
}

/******************************************************************
H1, H2, H3, H4, H5 STYLES
******************************************************************/

h1, .h1, h2, .h2, h3, .h3, .siteName, h4, .h4, .topMenu .nav > li > a, .button, button {
    font-family: $headings;
    font-display: fallback;

    a {
        text-decoration: none;
    }
}

.fal {font-weight:300;}

#accountMenu .h2 {font-family:$sans-serif; font-display: fallback; }

h4, .h4 {font-weight: 300;line-height: 1.2;}
strong, h1, .h1, h2, .h2, h3, .h3,  h5, .h5, h6, .h6, .topMenu .nav > li > a {font-weight: 700;}

h1, .h1, h2, .h2{margin:0.35em 0;}
h3, .h3, h5, .h5, h6, .h6{margin:0.75em 0;}
#accountMenu .h2, #address .h2, .slideshow_caption .h1,  .slideshow_caption .h2,#pushMenu p  {margin:0em 0;}
.slideshow_caption .h1 {text-shadow:1px 1px rgba(0,0,0,0.8);}
h3, .h3, h4, .h4, .siteName strong, .topMenu .nav > li > a, thead, .slideHeading, .h5, h5, .button, button {
    text-transform: uppercase;
}

/*h3, .h3, .siteName strong, .topMenu .nav > li > a, thead {letter-spacing:1px;}*/

h1, .h1, .phone { font-size: 1.6em;}
h2, .h2, h4, .h4, .slideDescription, .fa-ul li, .bigger, .topBlock > div p, .phone {font-size: 1.2em;}
h3, .h3, h5, .h5, h6, .h6, #breadcrumbs .siteName strong, .accountMenu {font-size: 1em;}
h3, .h3 {
    &:before {
 
    }
}
#rightcol h4, #rightcol .h4 {
    position: relative;
    text-align: center;
    color: black;
    margin-bottom: 1.5em;
    
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      top: 200%;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      background-color: $color-secondary;
    }  
  
    &:after {
      content: '';
      position: absolute;
      width:20%;
      height: 3px;
      top:200%;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: $color-secondary;
      @include transition(all 0.2s ease-in-out);
    }
  
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }

h3, .h3, h4, .h4, .topMenu .nav > li > a, .h5, h5 {
    letter-spacing: 1px;
}
.responsive .h3:after {
    display: none;
    margin-bottom: 0;
}

.smtext, #breadcrumbs, thead, .respond-form label, .holdForm ul { font-size: 0.85em; }


/*************************
Colours
*************************/


.darkGreen, .darkHeader #accountMenu, #accountMenu .h2, .slideshow_caption .h2, #accountMenu .h2, ul.responsive3 .slick-list .slick-track .slick-slide .slideshow_caption, .darkHeader .phone, body.Home #content ul li::before, blockquote:before, body.Home .columnlayout h2 a:hover,
.respond-form h2, .fa-li, .slideshow_caption a.button, .heading h1 {
    color: $color-primary;
}


.black, 
 a.button, button,
body.Home #content #leftcol > div:first-of-type,
ul.responsive3 .h2.slideDescription,
body.Home #content #leftcol > div:first-of-type, 
body.fullWidth #content #leftcol > div:first-of-type,
body.CurrentSpecials.fullWidth #content #leftcol > div:nth-child(odd),
body#body_476 #content #leftcol > div:nth-child(odd),
body.Apartment #leftcol > div:first-of-type, 
#breadcrumbs, body.CurrentSpecials.fullWidth #content #leftcol > div:nth-child(odd) h2 a, #breadcrumbs a
{color: $black; }

.grey
{color: lighten($black, 40%); }

.white, .pushy a, .button.secondary,
.slick-slider button:before, a.button.secondary, .button.bigger,
.responsive .h3,
.holdForm, .holdForm h2, .holdForm a.button, #transparentBG,
#footer #facebook a, #copyright, #footer, #footer a, .cd-top i, #cta a.button, .button.primary, a.button.primary, a.button:hover, .button.tertiary,
#hero .slick-slider button:before, #hero .heading h1, #hero .heading .h1,
#homeMission, #footer h4 {
    color: $white;
}

h3, .h3, .h4, h4, #pushMenu .nav li ul li a {
    color: $color-secondary;
}
/*************************
Turn off printable items
*************************/
.printable {
    display: none;
}
	

/*********************
SPRITE
*********************/
/*use modernizer script stop detect browser fallback for next gen images*/
/*.no-webp .box { color: red; }
.webp .box { color: green; }*/	
/*.no-js #logo, .no-webp #logo { background-image: url('../images/logo.png'); }
.webp #logo { background-image: url('../images/logo.webp'); }*/

#logo {
    display: block;
    margin: 0 auto;
    background: url("../images/logo.svg");
    background-position: center top;
    width: 180px;
    height: 63px;
    background-size: 180px 63px;
}



.button, button {
    padding:0.3em 0.7em;
}
/*********************
HEADER STYLES
*********************/
/* STICKY NAV - This is where the header is set to be sticky. 
We can also change colour and size here - effects all take place onScroll (waypoints triggers this) */
body:not(.Home) .clearHeader {
    border-bottom: 2px solid $color-primary;
    background: $white;
    position: relative;
}
.header {

    .clearHeader {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.9);
        @include transition(all 0.2s ease-in-out);
        box-shadow: 0 0 5px 0 black;
    }

    .darkHeader {
        position: fixed;
        background-color: $white;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        @include transition(all 0.2s ease-in-out);
        top: 0;
    }
}

/* position header elements as needed */

.header {

    .row {min-height: 70px;}
    
    position: relative;
       

    .menu {width:100%;}

    .menu-btn, #logo, #accountMenu{
		position: absolute;
	}

    #accountMenu {
		right: 2%;
        @include center(false, true);
        .mobileOnly {margin-left:0.5em}
	}
    
    .menu-btn {
		left: 2%;
        @include center(false, true);
	}

    #logo {
		@include center(true, false);
		top: 0.25em;
	}

    #mainMenu {
        display:none;
    }

    
}



/***********************
HERO - HERO
************************/

#hero {
	position: relative;
    overflow: hidden;
}

body.Home #hero .row { @include center(true, true); width: 85%;}

body:not(.Home) #hero {
    position: relative;
    height:250px;
    height:20vh;

    p {margin: 0 0;}
    p img, .heading{ @include center(true, true); }
    p img {min-height: auto; max-width: 1000px;z-index:-1;}
    .heading { padding:1em; width:90%; z-index:2; text-align:center; }

    &:before {content:""; position:absolute; display: block; width:100%; height:100%; background-color: rgba(0, 0, 0, 0.35); z-index:0;}
}


	
/*********************
NAVIGATION STYLES
*********************/
#touchMenu,
.menu-btn {display:block;}

#mainMenu {
    display:none;
}

#pushMenu {
    ul:nth-of-type(2){
        > li:first-of-type{display:none;} /*hide home link in 2nd nav*/
    }
}

.topMenu, #pushMenu {
    /*list-style:none;
	float: right;
	clear: right;*/
    a.toggleMenu {
        width: 100%;
        padding: 2% 4%;
        text-transform: uppercase;
        margin-bottom: 0;

        span {
            display: block;
            width: auto;
            float: right;
            padding-left: 0.5em;
        }
    }

    .nav {
        /*margin-top:0;
			position: absolute;
			width: 96%;
			z-index: 99999;*/
        li {
            position: relative;
            text-align: left;

            a {
                display: block;
                text-decoration: none;
                padding: 0.5em 0.5em;
                background: darken($color-secondary, 10%);
                border-bottom: 0.5px dotted $white;

                &:hover, &:focus {
                    background: darken($color-secondary, 5%);
                }

                &.parent:before {
                    content: "\f107";
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 300;
                    display: block;
                    float: right;
                    font-size: large;
                    padding-right: 0.5em;
                    line-height: 1.2;
                }
            }

            &.hover {
                > a.parent:before {
                    content: "\f106";
                }
            }

            &:first-child {
            }

            &:last-child {
            }
            /* Sub-menu level 2 */
            ul,
            ul.sub-menu,
            ul.children {
                margin-top: 0;
                border-top: 0;
                display: none;

                li {

                    a {
                        padding-left: 1em;
                        background: $white;
                        border-bottom: 1px solid rgba(130, 154, 193, 0.21);

                        &:hover,
                        &:focus {
                            background: darken($white, 8%);
                        }

                        &:link {
                        }
                    }

                    &:first-child {
                    }

                    &:last-child {
                    }
                    /* Sub-sub-menu level 3	*/
                    ul {

                        li {

                            a {
                                padding-left: 1.5em;
                                background: $black;

                                &:hover,
                                &:focus {
                                    background: lighten($black, 5%);
                                }
                            }
                            /* Sub-sub-menu level 4	*/
                            ul {

                                li {

                                    a {
                                        padding-left: 2em;
                                        background: lighten($black, 30%);
                                    }
                                }
                            }
                            /* end level 4	*/
                        }
                    }
                    /* end level 3	*/
                }
            }
            /* end level 2	*/
            /* Hover effects */
            &:hover ul {
            }
        }
        /* end .menu li */
    }
    /* end .nav */
    .nav > li.hover > ul {
        left: 0;
        display: block;
    }

    .nav li li.hover > ul {
        left: 100%;
        top: 0;
        display: block;
    }
}/* end .topMenu */



/*********************
CONTENT STYLES
*********************/
/* content area all inner pagesset different or alternate row colours here*/
body.fullWidth #content #leftcol > div:first-of-type,
body.Home #content #leftcol > div:first-of-type,
#intro,
#breadcrumbs
 {background: lighten($color-secondary, 40%);}

/* content area all inner pages */
#content, #homeBlocks, #bottomBlocks  {background:$white;}




.fa-ul {
    padding-left: 60px;}
	
	
			p {}


			ul, ol, table, dl {}

			ul.blue-list { list-style:none;

				li::before {
                    content: "\2022"; 
                    color: $color-primary;
                    display: inline-block; 
                    width: 1em;
                    margin-left: -1em;
                    font-size:1.25em;
				}


			}


			ol {

				li {}

			}

			blockquote {
				margin: 0 0 0 0;
				text-align: left;
								
			}
			
			blockquote {
				padding: 1em;
				p {
					padding-left: 2em;
					margin-top: 0;
				}
				h5 {
					margin: 0.4em 0;
				}
								
				&:before {
					content: "\f10d";
					font-family: "Font Awesome 5 Pro";
                    font-weight: 300;
					display: block;
					float:left;
					padding-right: 0.5em;
					line-height: 1.2;
									
				}
				
				a {
					text-decoration: none;
				}
				
				em{
					text-align: right;
					}

			}

			dl {}

			dt {}

			dd {
				margin-left: 0;
				font-size: 0.9em;
				color: #787878;
				margin-bottom: 1.5em;
			}

			img {
				/*margin: 0 0 1.5em 0;*/
				max-width: 100%;
				height: auto;
			}


	#breadcrumbs { 
		/*border-bottom: 1px dotted lighten($color-secondary, 0%);*/
				
		> div {
            /*text-align: center;*/
             div {
			    display: inline;
            }
		}
	}
	
	

/*********************************
 BLOCKS
*********************************/

#leftBlocks {
    background: url(../images/Laurel-Springs-5.jpg);
    background-size: cover;
    padding-top: 2em;

    .block {
        .sixcol {
            .button {
                margin-top: 5px;
            }
        }
    }
}

#rightcol {
    display: none;
}

#transparentBG {
    background: rgba(0,0,0,0.7);
    padding: 2em;
}

.block {}

    .columnlayout {
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 100%;
        padding-left: 0;
        justify-content: flex-start;

        /*turn off basic li styling*/
        padding: 0 0 0em 0;
        li:before {display:none;}
        li {margin-bottom: 0em; padding-left:0em;}

        > div, li, article {
            display: inline-block;
            vertical-align: top;
            flex: 1 1 auto;
            flex-grow: 0;
            width: 100%;
            padding:0 1em;
            > div {
                width: auto;
            }
        }

        article.newsBlock {width:100%;}
    }

   /* #gallery */
   #gallery li {padding:0 0.25em;}


    /* RIGHT BLOCKS */
    #rightcol {
        .block {
            margin-bottom:1em;
            .respond-form, #newsletter {
                form {
                    width: 100%;
                }
            }


            ol, ul {
                padding: 0em 0em 1em 0em;

                li {
                    list-style: none;
                    line-height: 1.6;
                    padding:0.25em;
                    margin-bottom:0.25em;
                    border-bottom: 1px dotted lighten($black, 30%);

                    a {
                        display: block;
                        text-decoration: none;
                        
                    }
                    a:hover, a.activesibling { }
                }

                li  {
                    &:before {
                        content: "\f105";
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 300;
                        display: block;
                        float: left;
                        font-size: large;
                        padding-right: 0.5em;
                        line-height: 1.2;
                    }
                }
            }
        }
        /* end .block */
    }
    /* end #rightcol */


    /*TOP BLOCKS INNER PAGES*/
  
    body:not(.Home) #topblocks {

        background-color: darken($white, 10%);

        div.block { 
            max-height: 350px;
		    position: relative;
            overflow:hidden;
            margin-bottom:0;

            > p {margin:0 0;}

            img {
              /*max-height: 500px; 
              height: 80vh;
              max-width: none;*/
              width: auto;
              height: auto;
            }
		
		    .caption {
			    @include center();
                /*background-color:rgba(255, 255, 255, 0.6);*/
			    width: 95%;
			    text-align: center;
                padding: 1em;
		    }
        }

    }
   



	
/******************************************************************
FORM STYLES
******************************************************************/
    /* show/hid fields on gift voucher form*/
    .hide {
        
    }


    .respond-form {
        margin: 0em auto;
        padding: 0%;
        min-width: 190px;
        background-color: transparent;
    }

    .respond-form, #address {
        /* not nested as not all forms contain a table */
        table {
            width: 100%
        }

        form {
            margin: 0 0;

            li {
                list-style-type: none;
                clear: both;
                margin-bottom: 0.7335em;

                label,
                small {
                    display: none;
                }
            }

            fieldset {border:0; margin:0 0; padding: 0 0 0 0;}

            input[type=text],
            input[type=email],
            input[type=date],
            input[type=url],
            input[type=tel],
            textarea,
            select {
                padding: 1em;
                background-color:transparent;
                margin: 0 0 1em 0;
                display: table-cell;
                width: 100%;
                max-width: 100%;
                border: none;
                border-bottom:2.5px solid $color-secondary;

                &:focus {
                    /*background-color: #fff;*/
                }
                /* form validation - comment out as editor doesn't support some of the html5 validation techniques */
                /* &:invalid {
				outline: none;
				border-color: #fbc2c4;
				background-color: #f6e7eb;
				-webkit-box-shadow: none;
				   -moz-box-shadow: none;
				    -ms-box-shadow: none;
						box-shadow: none;
			}*/
            }

            input[type=checkbox] {
                margin-right: 5px;
            }

            input[name="180_LeaseEnquiry"] {
                margin-bottom: 1em;
            }


            .required {
                background-position: right top;
                background-repeat: no-repeat;
            }

            textarea {
                height: 80px;
            }

            img {
                float: left;

                &.fullwidth {
                    max-width: 100%;
                }
            }
        }
    }

    #address form {
        margin: 0 auto;
        input[type=email] {width:auto;padding: 0.5em;}
        input[type="submit"] {min-height: 36px;}
    }


/*********************
LEFT COLUMN
*********************/
    #leftcol {
        padding-bottom: 0em;

        #div307 {
            div {
                text-align: center;
            }
        }
    }


    

/*********************
FOOTER STYLES
*********************/
#bottomBlocks, #cta {
    background: lighten($color-secondary, 40%);
}

#bottomBlocks {
    position: relative;
    background-image: url(../images/leaf.png);
    background-size: 1200px;
    background-repeat: no-repeat;
    background-blend-mode: color-burn;
    padding:2em 0;
}

#footer {
    background:$color-secondary;

    > div {
        i {
            padding-right: 0.25em;
        }

        nav {
            ul {
                li {
                    border-bottom: 1px dotted $color-primary;

                    a {
                        display: block;
                        line-height: 2;
                    }
                }
            }
        }
    }

    #copyright {
        .first {
            a {
            }
        }

        .last {
        }
    }
}

    /* ================================================ */
    /* SLIDESHOWS */
    /* ================================================ */
    .rslides {
        position: relative;
        list-style: none;
        overflow: hidden;
        width: 100%;
        padding: 0;
        margin: 0;

        li {
            position: absolute;
            display: none;
            width: 100%;
            left: 0;
            top: 0;
            /*padding: 2%;*/
            background: $white;
        }

        img {
            display: block;
            height: auto;
            float: left;
            width: 100%;
            border: 0;
        }
    }

    .rslides_nav {
        display: none;
        visibility: hidden;
    }

    .slides1_nav {
        display: none;
        visibility: hidden;
    }

#leftcol li.slick-slide:before,
#leftcol ul.responsive .slick-dots li:before  {display:none;}

/* ================================================ */
/* SLIDESHOWS */
/* ================================================ */
/*.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-perspective: 1000px;
}*/
/* SLIDER */
    .slick-slider {
        padding: 0;
        list-style: none;

        .slick-list {
            margin: 0 auto;

            .slick-track {

                .slick-slide {
                    position: relative;
                	padding:0;

                    .slideshow_caption {
                    }
                }
            }
        }

        button {
            padding: 0.2em;
            height: auto;
            z-index: 9999;

            &:before {
                content: "\f104";
                font-family:"Font Awesome 5 Pro";
                font-weight: 300;
                height: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        .slick-next:before {
            content: "\f105";
            padding-left: 0.1em;
        }
    }

    .slick-prev, .slick-next {
        top: 48%;
        z-index: 9999;
    }

    .slick-prev {
        left: 1px;
    }

    .slick-next {
        right: 1px;
    }

    .slick-arrow:hover, .slick-arrow:focus, .slick-arrow:active {
        border: none;
    }

    .slick-slider .slick-list, .slick-track, .slick-slide, .slick-slide img {
        width: 100%;
    }

    .slick-dots li button:before, .slick-dots li.slick-active button:before {color:$white;}

    

    /*Full width slider x3 with captions, dots and arrows */
   
    ul.responsive {
        /*padding-bottom: 3em;*/
        overflow: visible;

        .slick-list {

            .slick-track {

                .slick-slide {

                   img {@include transition(all 0.3s ease-in-out);}
                    
                    &:hover {
                        img {@include transform($zoom);@include transition(all 0.3s ease-in-out);}
                    }

                    .slideshow_caption {
                        /*text-align: center;*/
				        width: 80%;
                        background-color: rgba(0, 0, 0, 0.7);
                        opacity: 1;
                        padding: 0.5em;
                        bottom:0;
                        @include center(true, false);
                        @include transition(all 0.3s ease-in-out);
                    }

                }

            }
        }

      
        .slick-prev {left: 0 }
        .slick-next {right: 0}
        .slick-dots {bottom:0; li:before {display:none;}}
    }

    /*Full width slider with captions */
ul.responsive3 {
    margin-bottom: 0;

    .slick-list {

        .slick-track {

            .slick-slide {
                height: 420px;
                height: 80vh;

                &:before {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background:rgba(0,0,0,0.4);
                
                 }

                    picture {
                        position: relative;
                    }

                    img {
                        /*height: 420px;*/
                        max-width: none;
                        width: auto;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translate(-50%,0);
                        -webkit-transform: translate(-50%, 0);
                        -ms-transform: translate(-50%,0);
                        transition: transform 6s;
                        position: relative;
                        min-width: 100%;
                        min-height: 100%;
                        z-index: -1;
                    }

                    p:last-of-type {
                        margin-bottom: 0.75em;
                    }

                    a.button:first-of-type {
                        animation-delay: 1s;
                    }

                    a.button {
                        animation-delay: 1.5s;
                    }
                }

                .slick-slide.slick-active {

                    img {
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translate(-50%, 0) scale(1.05);
                        -webkit-transform: translate(-50%, 0) scale(1.05);
                        -ms-transform: translate(-50%, 0) scale(1.05);
                        position: relative;
                        min-width: 100%;
                        min-height: 100%;
                    }
                }


                .slideshow_caption {
                    opacity: 0;
                    visibility: hidden;
                    display: none;
                    @include transition(all 0.5s ease-in-out);
                    position: relative;
                }

                .slideshow_caption.activate {
                    background: rgba(255, 255, 255,0);
                    opacity: 1;
                    visibility: visible;
                    display: block;
                    text-align: center;
                    @include transition(all 0.5s ease-in-out);
                }

                .slideshow_caption p.h5 {
                    top: -15%;
                    @include center(true, false);
                }
            }
        }
    }
    /************************
RESPONSIVE VIDEO WRAPPER
************************/
    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 35px;
        height: 0;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    /*********************
CUSTOMISE colorbox
*********************/
    #cboxContent {
        padding: 0 40px
    }

    #cboxCurrent, #cboxNext, #cboxNext, #cboxPrevious, #cboxClose {
        top: 4px;
    }

    #cboxNext, #cboxPrevious {
        top: 50%;
    }

    #cboxPrevious {
        left: 0;
    }

    #cboxNext {
        right: 0;
    }

    #cboxTitle {
        font-size: 120%;
        line-height: 1.8em;
    }

    #cboxLoadedContent {
        margin-top: 28px;
    }
    /*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        display: inline-block;
        height: 40px;
        width: 40px;
        text-align: center;
        position: fixed;
        bottom: 3em;
        right: 0.5em;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        white-space: nowrap;
        background: darken($color-tertiary, 5%);
        visibility: hidden;
        opacity: 0;
        @include transition(opacity .3s 0s, visibility 0s .3s);
        border-radius: 50%;


        i {
            margin: auto;
            line-height: 2.5;
            @include center();
        }
    }

    .cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
        @include transition(opacity .3s 0s, visibility 0s .3s);
    }

    .cd-top.cd-is-visible {
        /* the button becomes visible */
        visibility: visible;
        opacity: 1;
    }

    .cd-top.cd-fade-out {
        /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
        opacity: .5;
    }

    .no-touch .cd-top:hover {
        background-color: #e86256;
        opacity: 1;
    }


